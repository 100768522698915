import * as React from "react"
import { Container, Box, Grid, Typography, makeStyles } from "@material-ui/core"
import Seo from "../../../components/seo"
import Menu from "../../../components/festival/menu"
import Band from "../../../components/festival/band"
import { StaticImage } from "gatsby-plugin-image"
import {Link} from 'gatsby'

const galine = <>Galine brak meermaals onze harten en speelt zaterdag op het thuisfront in Kortrijk. Ze wordt in hetzelfde rijtje als Oscar and the Wolf en Lana del Ray geplaatst, omdat haar ‘pop for introverts’ steeds vergezeld wordt door een strelende duistere ondertoon. Ze speelde eerder al de AB plat en nam de juryleden van de Nieuwe Lichting bij de hand alsof het niets was. Statisch blijven onder haar bezwerende stem is geen optie. Maar geen zorgen, er zullen tissues aanwezig zijn. </>
const elefan = <>Deze Brusselse patsers zijn multigetalenteerd. Met hun bewezen bekwaamheid op verschillende ‘klassieke’ muziekinstrumenten creëren ze unieke elektronische muziek. Ambient en ongedwongen, ambachtelijk en toch futuristisch, dat is wat hen typeert.</>
const shoreShot = <>Prepare for impact wanneer Shore Shot eraan komt!
Zoals de naam doet vermoeden, vindt dit collectief zijn oorsprong aan de
Belgische kust. “Op z’n Oostends” brengen de muzikanten naadloos de
ritmische elementen uit funk, soul, jazz en turntablism samen. Zet daarboven
de zwoele stem van zangeres Frie Maline en de crisp raps van MC
Mastakush, en je krijgt de eclectische maar zeer dansbare sound waar Shore
Shot z’n handtekening van maakt.
</>

const galineImage =  <StaticImage 
src="../../../images/festival/woensdag/galine.png" 
alt="Galine"
placeholder="blurred"
/>  

const elefanImage =  <StaticImage 
src="../../../images/festival/woensdag/elefan.png" 
alt="Elefan"
placeholder="blurred"
/>  

const shoreShotImage =  <StaticImage 
src="../../../images/festival/woensdag/shoreshot.png" 
alt="Shore Shot"
placeholder="blurred"
/>  

const useStyles = makeStyles(theme => ({
  pijl: {
      float: 'right',
  },
  title:{
    textAlign: "center"
  }
 }));


const WoensdagPage = () => {
  const classes = useStyles();
  return (

    <Container maxWidth="lg">
      <Seo title="Tomatostories - Lineup woensdag" />
      <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Menu/>
            </Grid>
            <Grid item xs={12} className={classes.title}>
              <Typography variant="h4" component="h1" color="primary">
                Woensdag 18/08
                 <Link to="/festival/lineup/donderdag">
                  <StaticImage 
                      src="../../../images/pijl-rechts.png" 
                      alt="Donderdag 19/08" 
                      placeholder="tracedSVG"
                      height={60}
                      className={classes.pijl}
                      />
                 </Link> 
              </Typography>
              
            </Grid>
            <Band text={galine} time="19u" title="Galine" image={galineImage} orientation="left"/>
            <Band text={elefan} time="20u30" title="Elefan" image={elefanImage} orientation="right"/>
            <Band text={shoreShot} time="22u" title="Shore shot" image={shoreShotImage}  orientation="left"/>
          </Grid>
        </Box>    
      </Container>

)}

export default WoensdagPage
